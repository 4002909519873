<template>
  <div class="mylogin a-flex-ccc a-w-100 a-h-100">
    <div class="a-ff-zihun title">雅迪管理系统</div>
    <div class="login">
      <div class="a-flex-ccfs loginoutbox a-w-100 a-h-100">
        <div class="loginbox a-flex-ccc">
          <div class="a-fs-20 a-fw-b logintitle">登录</div>
          <el-form ref="loginForm" :model="loginForm" :rules="rules" class="loginbox-form">
            <el-form-item prop="mobile" class="a-mt-30">
              <el-input
                v-model="loginForm.mobile"
                type="tel"
                clearable
                autocomplete="off"
                class="login-input"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password" class="a-mt-30">
              <el-input
                v-model="loginForm.password"
                type="password"
                show-password
                clearable
                autocomplete="off"
                class="login-input"
              ></el-input>
            </el-form-item>
            <div class="a-flex-rsbc">
              <el-checkbox v-model="savePassword" class="checkItem">记住密码</el-checkbox>
              <!-- <span class="a-fs-12">忘记密码？</span> -->
            </div>
            <el-button type="primary" class="sure" @click.native.prevent="submitForm('loginForm')">
              登录
            </el-button>
            <!-- <div class="a-fs-14 a-flex-rcc">
							还没账号,<span class="a-c-blue a-ml-10">去注册</span>
						</div> -->
          </el-form>
        </div>
      </div>
      <!-- <div class="point"></div> -->

      <el-image
        :src="require('@/assets/images/login_point2.png')"
        fit="contain"
        class="pointimage"
      ></el-image>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import util from "../../utils/index";
import validate from "../../utils/validate";

export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!validate.isvalidPhone(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        mobile: "",
        password: "",
      },
      rules: {
        mobile: [
          {
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
      },
      savePassword: false,
      url: "../../assets/images/login_bg_all.png",
    };
  },
  computed: {
    ...mapState({
      company: (state) => state.company.company,
      user: (state) => state.user.user,
    }),
  },
  mounted() {
    // if(this.$route.query.msg){
    // 	this.$message.error(this.$route.query.msg)
    // }
    this.getSaveAccount();
    if (util.getCookie("password")) {
      this.savePassword = true;
    }
  },
  methods: {
    ...mapActions({
      getUserInfo: "user/getUserInfo",
      getDefaultCompanyInfo: "company/getDefaultCompanyInfo",
    }),
    /**
     * 获取保存账户
     */
    getSaveAccount() {
      if (document.cookie.length > 0) {
        this.loginForm.mobile = util.getCookie("mobile");
        this.loginForm.password = util.getCookie("password");
      }
    },
    submitForm(formName) {
      const that = this;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          that.login();
        } else {
          this.$root.validWarning();
        }
      });
    },

    login() {
      const that = this;
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.mobileLogin,
          params: {
            mobile: this.loginForm.mobile,
            password: this.loginForm.password,
          },
          loadingMessage: "正在登录...",
        })
        .then(({ data }) => {
          this.$Utils.setToken(data.token, 1);
          this.getUserInfo();

          localStorage.setItem("loglevel:webpack-dev-server", "SILENT");
          that.getDefaultCompanyInfo().then((company) => {
            if (!company) {
              that.$root.sysError("您没有任何商户的权限,登录失败");
              this.$Utils.removeToken();
            } else {
              if (that.savePassword) {
                util.setCookie("mobile", that.loginForm.mobile, 7);
                util.setCookie("password", that.loginForm.password, 7);
              } else {
                util.removeCookie("mobile");
                util.removeCookie("password");
              }

              that.$message({
                message: "登录成功",
                type: "success",
              });
              that.$router.push({
                path: "/home/index",
              });
            }
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mylogin {
  background: url(../../assets/images/login_bg_all.png) center / cover no-repeat;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  overflow: hidden;
}

.title {
  color: #414345;
  font-size: 34px;
  // padding-top: 33px;
  // padding-left: 32px;
  letter-spacing: -6px;
  position: absolute;
  top: 33px;
  left: 33px;
}

.login {
  height: 100vh;
  width: calc(50vw + 216px);
  position: absolute;
  right: 0;
  // position: relative;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.loginoutbox {
  // padding-top: 20vh;

  .loginbox {
    max-width: 426px;
    padding: 24px 53px 32px;
    background: rgba(255, 255, 255, 0.7);

    border: 2px solid;
    // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.47)) 2 2;
    border-radius: 8px;
    border-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(4px);
  }

  .login-input {
    /deep/ input {
      border: none;
      background: rgba(255, 255, 255, 0);
      border-bottom: 1px solid #ccc;
      border-radius: 0;
      width: 320px;
    }

    /deep/ input:-webkit-autofill {
      box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1) inset;
      backdrop-filter: blur(4px);
    }
  }

  .checkItem {
    /deep/ .el-checkbox__label {
      font-size: 12px;
    }
  }

  .sure {
    width: 100%;
    font-size: 16px;
    padding: 12px;
    background: #007aff;
    margin-top: 32px;
    margin-bottom: 16px;
    border-radius: 4px;
  }
}

.point {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  // background: url(../../assets/images/login_point2.png) bottom right/contain no-repeat;
}

.pointimage {
  // height: 100%;

  // position: absolute;
  // right: -14%;
  // bottom: -30%;
  // pointer-events: none;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  transform: translate(359px, 28%);
}

.logintitle {
  letter-spacing: 10px;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #1677ff;
  border-color: #1677ff;
}
</style>
